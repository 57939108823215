var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "总结类型" } },
                    [
                      _c("el-input", {
                        attrs: { readonly: "" },
                        model: {
                          value: _vm.value.summaryName,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "summaryName", $$v)
                          },
                          expression: "value.summaryName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "提交人" } },
                    [
                      _c("el-input", {
                        attrs: { readonly: "" },
                        model: {
                          value: _vm.value.createName,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "createName", $$v)
                          },
                          expression: "value.createName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "提交人职位" } },
                    [
                      _c("el-input", {
                        attrs: { readonly: "" },
                        model: {
                          value: _vm.value.createPosName,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "createPosName", $$v)
                          },
                          expression: "value.createPosName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "提交人所属区域" } },
                    [
                      _c("el-input", {
                        attrs: { readonly: "" },
                        model: {
                          value: _vm.value.createOrgName,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "createOrgName", $$v)
                          },
                          expression: "value.createOrgName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "提交日期" } },
                    [
                      _c("el-input", {
                        attrs: { readonly: "" },
                        model: {
                          value: _vm.value.createDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "createDate", $$v)
                          },
                          expression: "value.createDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "报告覆盖时间段" } },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.value.reportStartDate))]),
                      _vm.value.reportOverDate
                        ? _c("span", [_vm._v("-")])
                        : _vm._e(),
                      _c("span", [_vm._v(_vm._s(_vm.value.reportOverDate))]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 24 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "总结" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", readonly: "" },
                        model: {
                          value: _vm.value.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "content", $$v)
                          },
                          expression: "value.content",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 24 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "计划" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", readonly: "" },
                        model: {
                          value: _vm.value.wordPlan,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "wordPlan", $$v)
                          },
                          expression: "value.wordPlan",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "fileImg", attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 24 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "附件" } },
                    _vm._l(_vm.value.pictures, function (item, index) {
                      return _c("el-image", {
                        key: index,
                        attrs: {
                          src: item.picUrl + item.picPath,
                          lazy: "",
                          "preview-src-list": item.imgList,
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleClickItem.apply(null, arguments)
                          },
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }