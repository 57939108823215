<template>
  <div>
    <el-form>
      <el-row :gutter="24">
        <el-col :md="8">
          <el-form-item label="总结类型" class="flex">
            <el-input readonly v-model="value.summaryName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="提交人" class="flex">
            <el-input readonly v-model="value.createName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="提交人职位" class="flex">
            <el-input readonly v-model="value.createPosName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :md="8">
          <el-form-item label="提交人所属区域" class="flex">
            <el-input readonly v-model="value.createOrgName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="提交日期" class="flex">
            <el-input readonly v-model="value.createDate"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="报告覆盖时间段" class="flex">
            <span>{{ value.reportStartDate }}</span>
            <span v-if="value.reportOverDate">-</span>
            <span>{{ value.reportOverDate }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :md="24">
          <el-form-item class="flex" label="总结">
            <el-input
              type="textarea"
              readonly
              v-model="value.content"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :md="24">
          <el-form-item class="flex" label="计划">
            <el-input
              type="textarea"
              readonly
              v-model="value.wordPlan"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24" class="fileImg">
        <el-col :md="24">
          <el-form-item class="flex" label="附件">
            <el-image
              :src="item.picUrl + item.picPath"
              lazy
              @click.stop="handleClickItem"
              :preview-src-list="item.imgList"
              v-for="(item, index) in value.pictures"
              :key="index"
            ></el-image>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    value: Object,
  },
  created() {
    this.$emit('input', this.value);
  },
  methods: {
    handleClickItem() {
      // 获取遮罩层dom
      this.$nextTick(() => {
        const domImageMask = document.querySelector('.el-image-viewer__mask');
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener('click', () => {
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector('.el-image-viewer__close').click();
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.flex {
  display: flex;
}
.el-row {
  margin: 0 !important;
}
/deep/ .el-form-item__label {
  width: 125px;
}
/deep/ .el-form-item__content {
  flex: 1;
}
/deep/ .el-textarea__inner {
  height: 80px;
}
.el-col {
  padding: 0 !important;
}
/deep/.el-image__inner ,
/deep/ .el-image__error{
  width: 280px;
  height: 250px;
  margin-right: 20px;
  margin-bottom: 20px;
}
/deep/ .fileImg .el-form-item__content > div{
  width: auto;
}
</style>
