<script>
import TablePage from '../../../../../components/table_page';
import Form from './form';

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      requestUrl: '/sfa/summary/summaryReportFroms',
      formConfig: {},
      modalConfig: {
        title: '查看附件',
        visible: false,
        type: '',
      },
    };
  },
  async mounted() {
    const colums = await this.getConfigList('sfa_task_work_summary');
    colums.map((v) => {
      const rowData = v;
      if (rowData.field === 'fileCustomize') {
        rowData.type = 'html';
        rowData.formatter = () => '查看';
      }
      return rowData;
    });
  },
  methods: {
    cellClick({ row, column }) {
      if (column.property === 'fileCustomize' || column.property === 'createName') {
        this.formConfig = {
          row,
        };
        this.openFull();
      }
    },
    modalClick({ val, row }) {
      this.formName = 'Form';
      this.formConfig = {};
      if (val.code === 'view') {
        this.formConfig = {
          type: val.code,
          row,
        };
        this.modalConfig.title = '查看';
        this.openFull();
      }
    },
  },
};
</script>
